import React from "react";
import axiosResiInstance from "../http/axiosResi";

export const createProperty = (address) => {
    return axiosResiInstance.post("/properties", {
        address,
    });
};

export const fetchProperty = (propertyUrl) => {
    return axiosResiInstance.get(`/properties/${propertyUrl}`);
};
