import React from "react";
import css from "../../css/search/agent-residential.module.css";
import PropTypes from "prop-types";
import Avatar from "../agents/Avatar";
import { THUMB_150_150 } from "../../service/photo";

class ListingAgentResidential extends React.PureComponent {
    render() {
        const { agent } = this.props;

        return (
            <div className={css.agent}>
                <div className={css.inner}>
                    <div className={css.title}>Offered by</div>
                    <div className={css.avatar}>
                        <Avatar photo={agent.photo} size={THUMB_150_150} />
                    </div>
                    <div className={css.name}>
                        {agent.first_name} {agent.last_name}
                    </div>
                    <div className={css.phone}>
                        {agent.phone_primary_number}
                    </div>
                </div>
            </div>
        );
    }
}

ListingAgentResidential.propTypes = {
    agent: PropTypes.object.isRequired,
};

export default ListingAgentResidential;
