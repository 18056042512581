import {
    getListingPhotoUrl,
    LISTING_THUMB_420_247,
} from "../../../service/photo";

import React from "react";
import PropTypes from "prop-types";

const PropertyPhoto = (props) => {
    const { primaryPhoto, address, className, size } = props;

    const photoSize = size ? size : LISTING_THUMB_420_247;
    const photoUrl = primaryPhoto
        ? getListingPhotoUrl(primaryPhoto, photoSize)
        : null;

    return (
        <div className={className}>
            {photoUrl && <img src={photoUrl} alt={address} />}
        </div>
    );
};

PropertyPhoto.propTypes = {
    primaryPhoto: PropTypes.string,
    address: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
};

export default PropertyPhoto;
