import React from "react";
import css from "../../css/search/agent-source.module.css";
import PropTypes from "prop-types";
import isRent from "../../helper/isRent";
import { AGENT_ACTIVE_STATUS } from "../../service/agent";

const ListingAgentSource = (props) => {
    const { listing } = props;

    return (
        <div className={css.agent}>
            {listing.agent.status === AGENT_ACTIVE_STATUS && (
                <div className={css.name}>
                    <div className={css.title}>
                        {isRent(listing) ? "Leasing Agent" : "Listing Agent"}
                    </div>
                    <div className={css.name}>
                        {listing.agent.first_name} {listing.agent.last_name}
                    </div>
                </div>
            )}
        </div>
    );
};

ListingAgentSource.propTypes = {
    listing: PropTypes.object.isRequired,
};

export default ListingAgentSource;
