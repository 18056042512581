import React, { useState } from "react";
import PropTypes from "prop-types";
import css from "../../css/search/listing.module.css";
import { getListingPhotoUrl, LISTING_THUMB_420_247 } from "../../service/photo";
import {
    useChain,
    useTransition,
    useSpring,
    useSpringRef,
    animated,
} from "@react-spring/web";

const preloadImg = (photos, index) => {
    if (index >= photos.length) {
        return false;
    }

    const img = new Image();
    img.src = getListingPhotoUrl(photos[index], LISTING_THUMB_420_247);
};

const Slideshow = (props) => {
    const { photos, altText } = props;
    const [index, setIndex] = useState(0);
    const springApi = useSpringRef();

    const firstSlideProps = useSpring({
        ref: springApi,
        from: { opacity: 0 },
        to: {
            opacity: 1,
        },
        config: { duration: 0 },
    });

    const transitionApi = useSpringRef();
    const transitions = useTransition(index, {
        ref: transitionApi,
        key: index,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
        delay: 1000,
        onRest: (_a, _b, item) => {
            preloadImg(photos, index + 1);
            if (index === item) {
                setIndex((state) => (state + 1) % photos.length);
            }
        },
        exitBeforeEnter: false,
    });

    useChain([springApi, transitionApi]);

    const firstPhotoUrl = getListingPhotoUrl(photos[0], LISTING_THUMB_420_247);

    return (
        <div>
            <animated.div
                className={css.photo}
                style={{
                    ...firstSlideProps,
                }}
            >
                <img src={firstPhotoUrl} alt={altText} />
            </animated.div>
            {transitions((style, i) => {
                const photoUrl = getListingPhotoUrl(
                    photos[i],
                    LISTING_THUMB_420_247
                );
                return (
                    <animated.div
                        className={css.photo}
                        style={{
                            ...style,
                        }}
                    >
                        <img src={photoUrl} alt={altText} />
                    </animated.div>
                );
            })}
        </div>
    );
};

Slideshow.propTypes = {
    photos: PropTypes.array.isRequired,
    altText: PropTypes.string,
};

export default Slideshow;
