import React, { useState } from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import debounce from "lodash.debounce";
import axiosResiInstance from "../../http/axiosResi";
import Router from "next/router";
import cssSpinner from "../../css/common/spinner.module.css";
import { geocodeAddress } from "../../service/my-property";
import { createProperty } from "../../service/property";

export const getLocation = (
    value,
    locationTypes = "zip,city,state,listing"
) => {
    return axiosResiInstance.get("/autocomplete", {
        params: {
            q: value,
            type: locationTypes,
        },
    });
};

export const processLocationText = async (locationText) => {
    if (!locationText) {
        return null;
    }

    let loc = await getLocation(locationText);
    loc = loc.data[0];

    if (!loc) {
        try {
            const response = await createProperty(locationText);

            Router.push(
                {
                    pathname: `/property`,
                    query: {
                        property: response.data.property_url,
                    },
                },
                `/p/${response.data.property_url}`
            );

            return null;
        } catch (e) {
            try {
                const geocodedAddress = await geocodeAddress(locationText);

                loc = geocodedAddress.data.zip;
            } catch (e) {
                return false;
            }
        }
    }

    if (loc.type === "listing") {
        Router.push(
            {
                pathname: `/listing`,
                query: {
                    listing: loc.url,
                },
            },
            `/l/${loc.url}`
        );

        return null;
    }

    return loc;
};

const AutocompleteLocation = (props) => {
    const {
        placeholder,
        locationTypes,
        theme,
        onBlur,
        id,
        clearOnSelect,
        showSpinner,
    } = props;

    const [value, setValue] = useState(props.value || "");
    const [isReset, setIsReset] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const loadSuggestions = async (value) => {
        const inputValue = value.trim().toLowerCase();
        let params = {
            q: inputValue,
        };

        if (locationTypes) {
            params.type = locationTypes;
        }

        axiosResiInstance
            .get("/autocomplete", {
                params,
            })
            .then((r) => {
                setSuggestions(r.data);
            })
            .catch((e) => {});
    };

    const clearSuggestions = () => {
        setSuggestions([]);
    };

    const debouncedLoad = debounce((value) => loadSuggestions(value), 300);
    const debouncedClear = debounce(clearSuggestions, 300);

    const onSelect = (selection) => {
        if (!selection || selection.type === undefined) {
            return null;
        }

        if (selection.type === "listing") {
            Router.push(
                {
                    pathname: `/listing`,
                    query: {
                        listing: selection.url,
                    },
                },
                `/l/${selection.url}`
            );
        }

        if (clearOnSelect) {
            reset();
        }

        props.onChange({ label: selection.label }, selection);
    };

    const shouldRenderSuggestion = (value) => {
        return value && value.trim().length > 2;
    };

    const onInputValueChange = (inputValue, stateAndHelpers) => {
        if (shouldRenderSuggestion(inputValue)) {
            debouncedLoad(inputValue);
        } else {
            debouncedClear();
        }

        props.onChange({ label: inputValue });

        return inputValue;
    };

    const reset = () => {
        setIsReset(true);
        setValue("");
        setSuggestions([]);
    };

    let inputProps = {
        placeholder,
        onBlur,
        className: theme.input,
        id: id ? id : "auto_suggest_id",
    };

    if (clearOnSelect && isReset) {
        inputProps.value = "";
    }

    const menuProps = {
        className: theme.suggestionsContainer,
    };

    return (
        <Downshift
            id={"autocomplete-location_" + inputProps.id}
            initialInputValue={value}
            onInputValueChange={onInputValueChange}
            onChange={onSelect}
            itemToString={(item) => (item ? item.label : "")}
        >
            {({
                getRootProps,
                getInputProps,
                getMenuProps,
                getItemProps,
                highlightedIndex,
                isOpen,
                selectItem,
            }) => (
                <div className={theme.container}>
                    <div {...getRootProps({}, { suppressRefError: true })}>
                        <input
                            inputMode="text"
                            {...getInputProps({
                                ...inputProps,
                                onChange: (e) => {
                                    selectItem({
                                        label: e.target.value,
                                    });

                                    if (clearOnSelect && isReset) {
                                        setIsReset(false);
                                    }
                                },
                            })}
                            autoComplete="off"
                        />
                        {showSpinner && (
                            <div
                                className={
                                    theme.spinner + " " + cssSpinner.spinner
                                }
                            />
                        )}
                    </div>
                    <ul {...getMenuProps(menuProps)} role="presentation">
                        {isOpen
                            ? suggestions.map((item, index) => (
                                  <li
                                      {...getItemProps({
                                          key: item.id,
                                          index,
                                          item,
                                          className:
                                              highlightedIndex === index
                                                  ? `${theme.suggestion} ${theme.suggestionHighlighted}`
                                                  : theme.suggestion,
                                      })}
                                  >
                                      {item.label_highlight && (
                                          <span
                                              dangerouslySetInnerHTML={{
                                                  __html: item.label_highlight,
                                              }}
                                          />
                                      )}
                                      {!item.label_highlight && (
                                          <>{item.label}</>
                                      )}
                                  </li>
                              ))
                            : null}
                    </ul>
                </div>
            )}
        </Downshift>
    );
};

AutocompleteLocation.defaultProps = {
    clearOnSelect: false,
    locationTypes: null,
    showSpinner: false,
};

AutocompleteLocation.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    locationTypes: PropTypes.string,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    theme: PropTypes.object.isRequired,
    clearOnSelect: PropTypes.bool,
    id: PropTypes.string,
    showSpinner: PropTypes.bool,
};

export default AutocompleteLocation;
