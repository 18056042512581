import React, { useContext } from "react";
import css from "../../css/search/listing.module.css";
import cssAgent from "../../css/search/agent-source.module.css";

import AreaIcon from "../../static/icons/area.svg";
import BedIcon from "../../static/icons/bed.svg";
import BathIcon from "../../static/icons/bath.svg";
import PropTypes from "prop-types";
import ListingAgentResidential from "./ListingAgentResidential";
import ListingAgentSource from "./ListingAgentSource";
import Slideshow from "./Slideshow";
import Link from "next/link";
import PropertyPhoto from "../common/photo/PropertyPhoto";
import CloseIcon from "../../static/icons/close.svg";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import moneyFormatter from "../../helper/moneyFormatter";
import { differenceInDays, parseISO } from "date-fns";
import Badge from "../listing/Badge";
import { HoverContext } from "./HoverContext";

const Listing = (props) => {
    const {
        listing,
        disableSlideshow,
        onClose,
        showViewsCounter,
        showDaysOnMarket,
        listingView,
    } = props;

    const hoverContext = useContext(HoverContext);

    const handleClick = (e) => {
        onClose();
        e.preventDefault();
    };

    const { agent, badge } = listing;
    const daysOnMarket = differenceInDays(
        new Date(),
        parseISO(listing.listed_date)
    );

    return (
        <Link
            href={`/listing?listing=${listing.url}`}
            as={`/l/${listing.url}`}
            className={
                agent && agent.client_id ? css.listingClient : css.listing
            }
        >
            <div
                className={css.content}
                onMouseOver={() => {
                    hoverContext.hoverHandler(listing.id);
                }}
                onMouseLeave={hoverContext.outHandler}
            >
                <div className={css.photoContainer}>
                    <LazyLoadComponent>
                        {disableSlideshow ||
                        hoverContext.hoveredListingId !== listing.id ||
                        !listing.slideshow ? (
                            <PropertyPhoto
                                primaryPhoto={listing.primary_photo}
                                address={listing.full_address}
                                className={css.photo}
                            />
                        ) : (
                            <Slideshow
                                photos={listing.slideshow}
                                altText={listing.full_address}
                            />
                        )}
                    </LazyLoadComponent>
                    {badge && (
                        <div className={css.badge}>
                            <Badge color={badge.color} label={badge.label} />
                        </div>
                    )}
                </div>

                <div className={css.details}>
                    {listing.living_area > 0 && (
                        <div>
                            <AreaIcon style={{ marginRight: "5px" }} />
                            {listing.living_area.toLocaleString()} Sq Ft
                        </div>
                    )}
                    <div>
                        <BedIcon style={{ marginRight: "5px" }} />
                        {listing.bedrooms} beds
                    </div>
                    <div>
                        <BathIcon style={{ marginRight: "5px" }} />
                        {listing.bathrooms} baths
                    </div>
                    {onClose && (
                        <button onClick={handleClick}>
                            Close <CloseIcon />
                        </button>
                    )}
                </div>
                <div
                    className={
                        agent && agent.client_id ? css.infoClient : css.info
                    }
                >
                    {agent && agent.is_listing_pro === 1 && (
                        <ListingAgentResidential agent={agent} />
                    )}
                    {agent && agent.is_listing_pro === 0 && (
                        <ListingAgentSource listing={listing} />
                    )}
                    {!agent && <div className={cssAgent.agent} />}

                    <div className={css.infoContent}>
                        <div className={css.address}>
                            <div>
                                {listing.address} {listing.unit_number || ""}
                            </div>
                            <div>
                                {listing.city.name}, {listing.state.abbr}{" "}
                                {listing.zip.code}
                            </div>
                        </div>
                        <div className={css.price}>
                            {listing.price &&
                                listing.price > 0 &&
                                moneyFormatter(0).format(listing.price)}
                        </div>
                    </div>
                </div>
                {(showDaysOnMarket || showViewsCounter) && (
                    <div className={css.settings}>
                        {showDaysOnMarket && (
                            <div>
                                <span className={css.settingsDays}>
                                    {daysOnMarket}
                                </span>{" "}
                                Day{daysOnMarket === 1 ? "" : "s"} on market.
                            </div>
                        )}
                        {showViewsCounter && (
                            <div>
                                {listingView === 0 ? (
                                    <>You haven't viewed this yet.</>
                                ) : (
                                    <>
                                        You viewed this {listingView} time
                                        {listingView === 1 ? "" : "s"}.
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Link>
    );
};

Listing.propTypes = {
    listing: PropTypes.object.isRequired,
    disableSlideshow: PropTypes.bool,
    onClose: PropTypes.func,
    showViewsCounter: PropTypes.bool,
    showDaysOnMarket: PropTypes.bool,
    listingView: PropTypes.number,
};

Listing.defaultProps = {
    disableSlideshow: false,
    onClose: null,
    showViewsCounter: true,
    showDaysOnMarket: false,
    listingView: 0,
};

export default Listing;
