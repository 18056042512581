import { createContext, useState } from "react";

export const HoverContext = createContext({
    hoverHandler: () => {},
    outHandler: () => {},
});

export function HoverListing({ children }) {
    const [hoveredListingId, setHoveredListingId] = useState(null);

    const handleHover = (listingId) => {
        setHoveredListingId(listingId);
    };

    const handleOut = () => {
        setHoveredListingId(null);
    };

    const value = {
        hoverHandler: handleHover,
        outHandler: handleOut,
        hoveredListingId: hoveredListingId,
    };

    return (
        <HoverContext.Provider value={value}>{children}</HoverContext.Provider>
    );
}
