import moneyFormatter from "../helper/moneyFormatter";

export const CATEGORY_SALE = "sale";
export const CATEGORY_RENT = "rent";

export const homeTypeOptions = [
    { value: "house", label: "House" },
    { value: "condo", label: "Condo" },
    { value: "townhome", label: "Townhome" },
    { value: "land", label: "Land" },
    { value: "mobile", label: "Mobile" },
    { value: "other", label: "Other/Misc" },
];

export const listingTypeOptions = [
    { value: "sale", label: "For Sale" },
    { value: "owner", label: "By Owner" },
    { value: "hud", label: "Hud Home" },
    { value: "foreclosure", label: "Foreclosures" },
    { value: "short_sale", label: "Short Sale" },
    { value: "auction", label: "Auction" },
];

export const findOption = (value, options) => {
    if (!value) {
        return null;
    }

    for (let option of options) {
        if (option["value"] == value) {
            return option;
        }
    }
};

export const findOptionLabel = (value, options) => {
    if (!value) {
        return null;
    }

    for (let option of options) {
        if (option["value"] === value) {
            return option["label"];
        }
    }
};

export const createPriceOptions = (prices, minPrice, maxPrice = null) => {
    let options = [];
    let label;

    prices.map((price) => {
        label = null;

        if (price === null) {
            label = "Any Price";
        } else if (
            (!minPrice || price > minPrice) &&
            (!maxPrice || price < maxPrice)
        ) {
            if (price >= 1000000) {
                label = moneyFormatter(1).format(price / 1000000) + "M";
            } else {
                label = moneyFormatter(0).format(price);
            }
        }

        if (label) {
            options.push({
                value: price,
                label,
            });
        }
    });

    return options;
};

export const minPriceOptions = (category, maxPrice = null) => {
    maxPrice = maxPrice && typeof maxPrice === "object" ? maxPrice.value : null;

    return {
        [CATEGORY_SALE]: createPriceOptions(
            [
                null,
                50000,
                100000,
                125000,
                150000,
                200000,
                225000,
                250000,
                300000,
                350000,
                400000,
                450000,
                500000,
                550000,
                600000,
                650000,
                700000,
                750000,
                800000,
                850000,
                900000,
                950000,
                1000000,
                1100000,
                1200000,
                1300000,
                1400000,
                1500000,
                1600000,
                1700000,
                1800000,
                1900000,
                2000000,
                2200000,
                2400000,
                2500000,
                2600000,
                2800000,
                3000000,
                3200000,
                3500000,
                3800000,
            ],
            null,
            maxPrice
        ),
        [CATEGORY_RENT]: createPriceOptions(
            [
                null,
                1000,
                1500,
                2000,
                2500,
                3000,
                3500,
                4000,
                4500,
                5000,
                5500,
                6000,
                6500,
                7000,
                7500,
                8000,
                8500,
                9000,
                9500,
                10000,
                11000,
                12000,
            ],
            null,
            maxPrice
        ),
    }[category];
};

export const maxPriceOptions = (category, minPrice = null) => {
    minPrice = minPrice && typeof minPrice === "object" ? minPrice.value : null;

    return {
        [CATEGORY_SALE]: createPriceOptions(
            [
                null,
                100000,
                125000,
                150000,
                200000,
                225000,
                250000,
                300000,
                350000,
                400000,
                450000,
                500000,
                550000,
                600000,
                650000,
                700000,
                750000,
                800000,
                850000,
                900000,
                950000,
                1000000,
                1100000,
                1200000,
                1300000,
                1400000,
                1500000,
                1600000,
                1700000,
                1800000,
                1900000,
                2000000,
                2200000,
                2400000,
                2500000,
                2600000,
                2700000,
                2800000,
                2900000,
                3000000,
                3500000,
                4000000,
                4500000,
                5000000,
                6000000,
                7000000,
                8000000,
                10000000,
            ],
            minPrice
        ),
        [CATEGORY_RENT]: createPriceOptions(
            [
                null,
                1000,
                1500,
                2000,
                2500,
                3000,
                3500,
                4000,
                4500,
                5000,
                5500,
                6000,
                6500,
                7000,
                7500,
                8000,
                8500,
                9000,
                9500,
                10000,
                12000,
                13000,
                14000,
                15000,
            ],
            minPrice
        ),
    }[category];
};
